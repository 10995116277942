import React, { useState } from "react"
import { Link } from "gatsby"
import logo from '../assets/logo.svg'
import Twitter from '../assets/icons/Twitter'
import Github from '../assets/icons/Github'
import AtSign from '../assets/icons/AtSign'
import SubscribeForm from './subscribeForm'
import Modal from './modal'

const Layout = ({ children }) => {
  const [ showSubscribe, setShowSubscribe ] = useState(false)

  return (
    <div>
      <Modal show={showSubscribe} onClose={() => setShowSubscribe(false)}>
        <SubscribeForm />
      </Modal>

      <nav className="header-gradient">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
              <Link to="/">
                <img src={logo} alt="kyleferg" className="w-20" />
              </Link>

              {/* right nav */}
              <div className="flex items-center">
                <a href="https://twitter.com/kylegferg" className="inline-block mr-4 text-gray-600 hover:text-gray-500">
                  <Twitter size="18" />
                </a>
                <a href="https://github.com/kyleferguson" className="inline-block mr-8 text-gray-600 hover:text-gray-500">
                  <Github size="18" />
                </a>

                <button type="button" onClick={e => setShowSubscribe(true)} className="px-4 py-2 rounded-full bg-blue-500 text-sm text-white font-bold shadow-sm flex items-center">
                  <span style={{paddingTop: '2px', marginRight: '2px'}}><AtSign size="15" /></span> 
                  Subscribe
                </button>
              </div>{/* right nav */}
          </div>
        </div>
      </nav>

      <main className="mb-8">
        { children }
      </main>

      <footer>
        <div className="container mx-auto px-4">
          <div className="text-sm text-gray-500 pt-3 pb-6">
            ©{new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
