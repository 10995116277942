import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'

const Modal = ({ children, show, onClose }) => (
    <>
        <Transition show={show}>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition
                        show={show}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
                        </div>
                    </Transition>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        {children}
                    </div>
                </div>
            </div>
        </Transition>
    </>
)

Modal.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.node,
    onClose: PropTypes.func,
}

Modal.defaultProps = {
    show: false,
    onClose: () => {},
}

export default Modal